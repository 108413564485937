import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Core/Seo"
import PrivateRoute from "../../router/PrivateRoute";
import Repositories from "../../components/Github/Repositories";

const RepositoriesPage = () => {
  return (
    <Layout>
      <SEO title="Repositories"
           description="Select a repository"/>
      <Repositories/>
    </Layout>
  );
};

function repositories(props) {
  return <PrivateRoute component={RepositoriesPage} {...props}/>
}

export default repositories;
