import React from "react"
import styled from "styled-components"

const StyledTextBlock = styled.div`
  display: block;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const TextBlock = ({children}) => {
  return (
    <StyledTextBlock>
      {children}
    </StyledTextBlock>
  );
};

export default TextBlock;
