import api from "./core/api";

async function getRepositories() {
  return await api.get('/repositories');
}

async function selectRepository(repository) {
  return await api.post('/repositories/select', {repository: repository});
}

const repositories = {
  getRepositories: getRepositories,
  selectRepository: selectRepository
};

export default repositories;
