import React, {useEffect, useState} from "react"
import LoginButton from "./LoginButton";
import TextBlock from "../Core/TextBlock";
import styled from "styled-components"
import ButtonWhite from "../Core/ButtonWhite";
import breakpoints from "../../styles/breakpoints";
import repositories from "../../backend/repositories";
import {navigate} from "gatsby";
import PageData from "../Core/PageData";

const RepositoryButton = styled.div`
  display: block;
  clear: both;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const AddMoreContainer = styled.div`
  display: block;
  margin-top: 40px;
  
  @media(min-width: ${breakpoints.md}) {
    margin-top: 80px;
  }
`;

const RepositoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Repositories = () => {
  const isCancelled = React.useRef(false);
  const [pageData, setPageData] = useState(null);

  const setRepositories = () => {
    if (isCancelled.current) {
      return;
    }

    setPageData(null);

    repositories.getRepositories()
      .then(({status, data}) => {
        if (isCancelled.current) {
          return;
        }

        if (status === 403) {
          navigate('/github/authenticate');

          return;
        }

        setPageData(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    setRepositories();

    return () => {
      isCancelled.current = true;
    };
  }, []);

  const selectRepository = (e, repository) => {
    e.preventDefault();

    repositories.selectRepository(repository)
      .then(({status}) => {
        if (status === 200) {
          navigate('/');

          return;
        } else {
          window.location.reload();
        }
      });
  };

  const closePopup = (popup, interval) => {
    if (popup) {
      popup.close();
    }

    clearInterval(interval);

    // const currentUrl = window.location.href;
    //
    // if (!(currentUrl.indexOf('/config') >= 0)) {
    //   navigate('/config');
    //
    //   return;
    // }

    setRepositories();
  };

  const authenticate = (e) => {
    e.preventDefault();

    const windowId = 'github-auth';
    const options = 'height=800,width=1200,left=200,top=200';

    const popup = window.open(
      '/github/app/authenticate',
      windowId,
      options
    );

    const currentUrl = window.location.href;

    let interval = window.setInterval(async () => {
      try {
        if (!popup || popup.closed !== false) {
          closePopup(popup, interval);

          return;
        }

        let matchUrls = [
          currentUrl,
          currentUrl + '/'
        ];

        const popupHref = popup.location.href;

        if (matchUrls.includes(popupHref) ||
          popup.location.pathname === 'blank') {
          closePopup(popup, interval);
        }
      } catch (e) {
      }
    }, 500);
  };

  const hasData = pageData !== null;

  const repositoriesData = pageData?.repositories;
  const hasInstallations = pageData?.hasInstallations;

  return (
    <PageData hasData={hasData}>
      {pageData &&
      <>
        <h1>Application Configuration</h1>

        {hasData && repositoriesData.length > 0 &&
        <>
          <TextBlock>
            <p><b>Select a repository</b></p>
          </TextBlock>

          <RepositoriesContainer>
          {repositoriesData.map(({id, name}, index) => {
              return (
                <RepositoryButton key={index}>
                  <ButtonWhite onClick={(e) => selectRepository(e, id)}>{name}</ButtonWhite>
                </RepositoryButton>
              );
            }
          )}
          </RepositoriesContainer>
        </>
        }

        <AddMoreContainer>
          <TextBlock>
            <p><b>Can't find your repo?</b></p>
            <p>Add another repository with github.</p>
          </TextBlock>

          <LoginButton authenticate={authenticate} hasInstallations={hasInstallations}/>
        </AddMoreContainer>
      </>
      }
    </PageData>
  );
};

export default Repositories;
