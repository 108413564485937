import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import githubIcon from "../../resources/img/icons/github.svg";

const StyledLoginButton = styled.button`
  ${fonts.montserratBold};
  text-decoration: none;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  margin: auto;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
  background: ${colors.green};
  border: 1px solid ${colors.green};
  
  &:hover {
    background: ${colors.green};
    color: ${colors.white};
    border-color: ${colors.green};
  }
  
  img {
    height: 32px;
    margin-left: 40px;
  }
`;

const LoginButton = ({authenticate}) => {
  return (
    <StyledLoginButton onClick={(e) => authenticate(e)}>
      Login with GitHub
      <img src={githubIcon} alt={'Login with GitHub'}/>
    </StyledLoginButton>
  );
};

export default LoginButton;
