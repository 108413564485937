import React from "react"
import Loader from "./Loader";

const PageData = ({hasData, children}) => {
  if (hasData) {
    return (
      children
    )
  } else {
    return (
      <Loader/>
    )
  }
};

export default PageData;