import React from "react"
import PropTypes from "prop-types"
import {navigate} from "gatsby"

const PrivateRoute = ({component: Component, ...props}) => {
  let isLoggedIn = false;

  if (typeof window !== 'undefined') {
    // todo use logged in status instead of token
    isLoggedIn = localStorage.getItem('token');
  }

  if (!isLoggedIn && typeof window !== 'undefined') {
    navigate('/login');

    return null;
  }

  return <Component {...props}/>;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
